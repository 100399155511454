<template>
  <div>
    <header class="title--container d-flex flex-column mb-3">
      <!--  ROW 1  -->
      <div class="d-flex justify-space-between align-center my-3">
        <div
          class="title__name"
        >
          {{ t('Floor plans') }}
        </div>
        <eewc-dropdown-menu
          v-if="!floors.isEditCamerasFloorEnabled"
          :menu-items="state.headerMenuOptions"
          :dark="false"
          :small="false"
          attach-to-parent=".v-application"
          @select="value => headerMenuAction(value)"
        />
      </div>

      <!--  ROW 2  -->
      <div
        v-if="!floors.isEditCamerasFloorEnabled"
        class="d-flex justify-space-between align-center"
      >
        <search-component />
        <select-wrappers />
      </div>
    </header>
    <div class="live-video-player">
      <div
        ref="videoElementContainer"
        class="live-video-player__video-container"
      >
        <video
          ref="videoElement"
          autoplay
          muted
        />
      </div>
      <eewc-loading-spinner
        :is-loading="state.loading"
        :size="60"
      />
      <camera-states
        v-if="state.cameraOffline"
        :status="state.camera.status?.connectionStatus"
        :is-big="true"
      />
      <div class="live-video-player__controls live-video-player__top-controls d-flex flex-row">
        <div
          v-if="state.camera.name"
          class="live-video-player__top-controls--name"
        >
          {{ state.camera.name }}
        </div>
        <div
          v-if="timeStamp"
          class="live-video-player__top-controls--timestamp"
        >
          {{ timeStamp }}
        </div>
        <div class="live-video-player__top-controls--options">
          <eewc-dropdown-menu
            :menu-items="state.menuOptions"
            button
            on-image
            attach-to-parent=".v-application"
            :tooltip="t('More')"
            @select="value => dropDownMenuAction(value)"
          >
            <!-- <template v-slot>
              <eewc-tooltip :text="t('More')">
                <template v-slot:content>
                  <eewc-button-common
                    onImage
                    icon
                    :iconSize="20"
                    appendIcon="$icon_more_vert"
                  >
                  </eewc-button-common>
                </template>
              </eewc-tooltip>
            </template> -->
          </eewc-dropdown-menu>
        </div>
        <eewc-tooltip :text="t('Close')">
          <template #content="{on, attrs}">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <eewc-button-common
                on-image
                icon
                :icon-size="20"
                append-icon="$icon_close"
                @click="redirectToFloorPlan"
              />
            </div>
          </template>
        </eewc-tooltip>
      </div>
      <div class="live-video-player__controls d-flex flex-column align-end">
        <template v-if="!state.cameraOffline && !state.loading">
          <eewc-tooltip :text="t(state.playing ? 'Stop' : 'Play')">
            <template #content="{on, attrs}">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <eewc-button-common
                  on-image
                  :icon-size="20"
                  icon
                  :append-icon="`$icon_${stopPlayIcon}`"
                  @click="togglePlay"
                />
              </div>
            </template>
          </eewc-tooltip>
          <eewc-tooltip
            v-if="state.showAudioControls"
            :text="t(audioIcon === 'sound_off' ? 'Unmute' : 'Mute')"
          >
            <template #content="{on, attrs}">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <eewc-button-common
                  on-image
                  icon
                  :icon-size="20"
                  :append-icon="`$icon_${audioIcon}`"
                  @click="toggleAudio"
                />
              </div>
            </template>
          </eewc-tooltip>
          <!--
          <eewc-tooltip :text="t('Make snapshot')">
            <template #content="{on, attrs}">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <eewc-button-common
                  on-image
                  icon
                  :icon-size="20"
                  :append-icon="`$icon_${snapshot}`"
                />
              </div>
            </template>
          </eewc-tooltip>
          <div class="live-video-player__controls--microphone">
            <eewc-tooltip :text="t('Turn micro on')">
              <template #content="{on, attrs}">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <eewc-button-common
                    on-image
                    icon
                    :icon-size="20"
                    :append-icon="`$icon_${microphone}`"
                  />
                </div>
              </template>
            </eewc-tooltip>
          </div>
          -->
        </template>
        <template v-if="!state.cameraOffline && !state.loading">
          <eewc-tooltip :text="t('Zoom in')">
            <template #content="{on, attrs}">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <eewc-button-common
                  v-if="!state.cameraSupportsPTZ"
                  on-image
                  icon
                  :icon-size="20"
                  append-icon="$icon_zoom_in"
                  @click="zoomIn"
                />
              </div>
            </template>
          </eewc-tooltip>
          <eewc-tooltip :text="t('Zoom out')">
            <template #content="{on, attrs}">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <eewc-button-common
                  v-if="!state.cameraSupportsPTZ"
                  on-image
                  class="mb-3"
                  icon
                  :icon-size="20"
                  append-icon="$icon_zoom_out"
                  @click="zoomOut"
                />
              </div>
            </template>
          </eewc-tooltip>
        </template>
        <!--
        <eewc-tooltip
          v-if="!state.cameraOffline && !state.loading"
          :text="t(state.showPTZControls ? 'Turn PTZ off' : 'Turn PTZ on')"
        >
          <template #content="{on, attrs}">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <eewc-button-common
                v-if="state.cameraSupportsPTZ"
                on-image
                class="mb-3"
                :icon-color="state.showPTZControls ? 'accent' : ''"
                icon
                append-icon="$icon_ptz"
                :icon-size="20"
                @click="state.showPTZControls = !state.showPTZControls"
              />
            </div>
          </template>
        </eewc-tooltip>
        -->
        <eewc-tooltip
          :text="t('Open Live View')"
        >
          <template #content="{on, attrs}">
            <div
              v-bind="attrs"
              v-on="on"
              class="mb-3"
            >
              <eewc-button-common
                icon
                append-icon="$icon_open"
                :icon-size="20"
                @click="openLiveViewInNewWindow"
              />
            </div>
          </template>
        </eewc-tooltip>
      </div>
      <ptz-controls
        v-if="state.cameraSupportsPTZ && state.showPTZControls && !state.cameraOffline"
        :camera-id="state.cameraId"
        :video-element="videoElement"
      />
      <audio
        ref="audioElm"
        :autoplay="true"
      />
    </div>
  </div>
</template>

<script setup>
// vue
import { useFeedsStore, useFloorsStore, useCamerasStore, useFloorplansStore, useAppStore } from '@/stores'
import router from "@/service/router";
import { reactive, onMounted, toRefs, ref, watch, computed, onUnmounted, watchEffect } from 'vue';

// service
import { t } from "@eencloud/eewc-components/src/service/locale";
import { useZoom } from '@/service/zoomHook';
import { useDeleteDialog } from '@/components/AppHeader/useDeleteFloorplanDialog';

// libraries
import LivePlayer from "@een/live-video-web-sdk";
import ApiList from "@eencloud/eewc-components/src/service/api";

// components
import PtzControls from "./PTZ.vue";
import CameraStates from "../../components/CameraStates.vue";

import SearchComponent from '@/components/AppHeader/SearchComponent.vue';
import SelectWrappers from '@/components/AppHeader/SelectWrappers.vue';
import { openHistoryBrowser } from '@/service/helpers'

const camerasStore = useCamerasStore();
const floorplans = useFloorplansStore()
const appStore = useAppStore();
const feedsStore = useFeedsStore();
const floors = useFloorsStore();
const browserSupportsWebCodecs = !!window.VideoDecoder;
const videoElement = ref(null);
const videoElementContainer = ref(null);
const state = reactive({
  cameraId: computed(() => router.currentRoute.params.cameraId),
  locationId: computed(() => router.currentRoute.params.locationId),
  floorPlanId: computed(() => router.currentRoute.params.floorPlanId),
  camera: computed(() => camerasStore.camera(router.currentRoute.params.cameraId)),
  floorPlanImg: computed(() => `/${router.currentRoute.params.floorPlanId}.png`),
  videoElement,
  videoElementContainer,
  streamType: null,
  playing: false,
  player: null,
  feedResponseObject: null,
  loading: false,
  cameraOffline: false,
  cameraSupportsPTZ: false,
  showPTZControls: false,
  showAudioControls: false,
  activeCameraId: router.currentRoute.params.cameraId,
  headerMenuOptions: [
    { name: 'Add new floor plan', disabled: false, value: 'navigateToAdd' },
    { name: 'Edit floor plan', disabled: false, value: 'navigateToEdit' },
    { name: 'Settings', disabled: false, value: 'navigateToSettings' },
    { name: 'Delete floor plan', disabled: false, value: 'navigateToDelete', warning: true }
  ],
  menuOptions: [
    // { name: "Settings", disabled: false, value: "settings" },
    { name: "History browser", disabled: false, value: "open-history-browser" },
    // { name: "Analytics", disabled: false, value: "analytics" },
    // { name: "Save image", disabled: false, value: "save" },
    // { name: "Search", disabled: false, value: "search" },
    // { name: "Delete camera", disabled: false, value: "delete", warning: true },
  ]
});
const { timeStamp, processTimeStamp } = useTimeStamp();
const { togglePlay, toggleAudio, redirectToFloorPlan, stopPlayIcon, audioIcon, snapshot, headerMenuAction, openLiveViewInNewWindow } = useControls(state, floorplans);
const { zoomIn, zoomOut } = useZoom(videoElement, videoElementContainer);
watchEffect(() => {
  processCameraProperties()
})
const imageLoaded = ref(false);

function processCameraProperties() {
  if (state.camera) {
    if (state.camera.status?.connectionStatus !== "online") {
      state.cameraOffline = true;
      state.loading = false;
      return;
    } else {
      state.cameraOffline = false;
      state.isPlaying = false;
      if (state.player) {
        state.player.stop();
      }
      initialize();
    }

    if (state.camera?.capabilities?.ptz?.capable) {
      state.cameraSupportsPTZ = true;
    }
  }
}

async function initialize() {
  try {
    state.loading = true;
    state.feedResponseObject = await feedsStore.getMainFeed(state.cameraId);
    let feedUrl;
    if (browserSupportsWebCodecs && state.feedResponseObject.multipartUrl) {
      state.streamType = "WebCodecs";
      feedUrl = state.feedResponseObject.multipartUrl+'&isoTimestamps=true'
    } else {
      state.streamType = "FLV";
      feedUrl = state.feedResponseObject.flvUrl;
    }
    if (!state.player) {
      state.player = new LivePlayer();
    }
    state.player.start({
      videoTech: state.streamType,
      videoElement: state.videoElement,
      feedUrl,
      jwt: ApiList.token,
      onFrame: (time) => onFrame(time),
      onStop: () => onStop(),
      onAudio: () => (state.showAudioControls = true)
    });
    state.videoElement.play();
    if (state.streamType === "FLV") {
      state.loading = false;
      state.showAudioControls = true;
      state.playing = true;
    }
  } catch (error) {
    state.loading = false;
    console.log(error);
  }
}


watch(() => state.playing, (isPlaying) => { // faking timestamps for FLV
  if (isPlaying && state.streamType === 'FLV' && state.camera?.timeZone?.zone) {
    state.fakeTimeInterval = setInterval(() => {
      if (state.camera?.timeZone?.zone) {
        timeStamp.value = new Date().toEENCameraTimeStamp(state.camera.timeZone.zone)
      }
    }, 1000);
  } else {
    clearInterval(state.fakeTimeInterval);
  }
});


function onFrame(time) {
  state.loading = false;
  processTimeStamp(time);
}

function onStop() {
  stopPlayIcon.value = "play";
  console.log("video stopped");
}

onMounted(async () => {
  try {
    if (state.camera.status?.connectionStatus === "online") {
      initialize();
    }
  } catch (error) {
    state.loading = false;
    console.log(error);
  }
});

onUnmounted(() => {
  state.isPlaying = false;
  if (state.player) {
    state.player.stop();
  }
});

function useTimeStamp() {
  const timeStamp = ref(null);

  function processTimeStamp(EENTimestamp) {
    const str = EENTimestamp.toString();
    const hour = str.substring(8, 10);
    const min = str.substring(10, 12);
    const secDotMilli = str.substring(12);
    const result = [hour, min, secDotMilli].join(":");
    timeStamp.value = result;
  }

  return { timeStamp, processTimeStamp };
}

function dropDownMenuAction(action) {
  switch (action) {
    case "open-history-browser": {
      openHistoryBrowser(appStore.isNewWebapp, state.cameraId);
    }
  }
}

function onLoad() {
  imageLoaded.value = true;
}

function useControls(state, floorplans) {
  const icons = reactive({
    stopPlayIcon: "stop",
    audioIcon: "sound_off",
    snapshot: "snapshot",
    microphone: "micro",
  });

  function toggleAudio() {
    if (state.streamType === "WebCodecs") {
      state.player.setAudioMuted(!state.player.getAudioMuted());
      icons.audioIcon = state.player.getAudioMuted() ? "sound_off" : "sound";
    } else if (state.streamType === "FLV") {
      state.player.toggleMute();
      icons.audioIcon = state.videoElement.muted ? "sound_off" : "sound";
    }
  }

  async function togglePlay() {
    if (state.player?.getPlayState()) {
      state.videoElement.pause();
      state.player.stop();
      icons.stopPlayIcon = "play";
    } else {
      await initialize();
      icons.stopPlayIcon = "stop";
    }
  }

  function redirectToFloorPlan() {
    const floorPlanId = router.currentRoute.params.floorPlanId;
    const locationId = router.currentRoute.params.locationId;
    router.push({ path: `/location/${locationId}/floor_plan/${floorPlanId}` });
  }

  function openLiveViewInNewWindow() {
    if (appStore.isNewWebapp) {
      const url = `${document.referrer}#/videoext/${state.cameraId}`;
      window.massageManager.postMessage(window.parent, 'openLiveView', url);
    } else {
      window.massageManager.postMessage(window.parent, 'openLiveView', state.cameraId);
    }
  }

  function headerMenuAction(action) {
    const floorsStore = useFloorsStore();
    const deleteDialog = useDeleteDialog();
    switch (action) {
      case 'navigateToEdit':
        floorsStore.$patch({isEditCamerasFloorEnabled: true});
        break;
      case 'navigateToDelete':
        deleteDialog.createDeleteDialog();
        break;
      case 'navigateToAdd':
        router.push(`/new-floor-plan`);
        break;
      case 'navigateToSettings':
        floorsStore.$patch({isEditSettingsActive: true});
        break;
    }
    redirectToFloorPlan();
  }
  return { togglePlay, toggleAudio, redirectToFloorPlan, headerMenuAction, openLiveViewInNewWindow, ...toRefs(icons) };
}

</script>

<style lang="scss" scoped>
@import "../../styles/public/main.scss";

.live-video-player {
  background-color: $primaryMedium;
  width: 100%;
  position: absolute;
  left: 0;
  top: 100px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 4px;

  .live-video-player__video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    overflow: auto;

    video {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  &__controls {
    right: 30px;
    bottom: 20px;
    position: absolute;
    &--microphone {
      margin-bottom: 60px !important;
    }

    button {
      z-index: 3;
    }

    display: grid;
    gap: 12px;
  }

  &__top-controls {
    top: 32px;
    bottom: unset;
    width: calc(100% - 60px);
    justify-content: end;

    &--timestamp, &--name {
      height: 36px;
      border-radius: 4px;
      background: rgba(33, 42, 52, 0.72);
      backdrop-filter: blur(4px);
      @include subtitle-2;
      width: fit-content;
      padding: 8px;
      color: white;
      cursor: default;
    }
    &--name {
      margin-right: auto;
    }
  }
}
.floorplan-preview {
  &__wrapper {
    @include flex-column;
    overflow: hidden;
    align-items: flex-end;
    background-color: $primaryWhite;
    border-radius: 4px;

    &__preview-collapsed-wrapper {
      width:100%;
      display: flex;
      justify-content: end;
      background: $primaryWhite;
      cursor: pointer;
      &:hover {
        filter: brightness(0.97);
      }
      &:active {
        filter: brightness(0.6);
      }
    }
  }

  &__image-wrapper {
    position: relative;
    height: 100%;
    display: inline-block;

  }
  &__image {
    filter: grayscale(1);
    max-width:300px;
    max-height:300px;
  }
}


</style>
