<template>
  <eewc-sidebar
    :title="`${t('Settings')} • ${currentLocation.name} • ${currentFloor.name}`"
    :actions="true"
    :drawer-open="open"
    @closeSidebar="$emit('close')"
  >
    <template #tabContent>
      <div
        class="floorplan__sidebar-settings--container"
      >
        <v-card flat>
          <v-card-text>
            <div class="sidebar-settings--options-container d-flex flex-column">
              <div>
                <eewc-form-field :label="t('Site')">
                  <div class="select-wrapper">
                    <eewc-select-box
                      :placeholder="currentLocation.name"
                      selection-slot
                      item-slot
                      :value="currentLocation"
                      :items="[currentLocation]"
                      disabled
                    >
                      <template #selection="{item}">
                        {{ item.name }}
                      </template>
                      <template #item="{item}">
                        {{ item.name }}
                      </template>
                    </eewc-select-box>
                  </div>
                </eewc-form-field>
              </div>
              <div>
                <eewc-form-field :label="t('Floor Level')">
                  <div class="select-wrapper">
                    <eewc-select-box
                      v-if="locationsStore.currentLocation"
                      :items="availableFloors"
                      :placeholder="String(currentFloor?.floorLevel ?? '')"
                      :value="newFloor.floorLevel"
                      disabled
                      selection-slot
                      item-slot
                      @input="selectFloor"
                    >
                      <template #selection="{item}">
                        {{ item.text }}
                      </template>
                      <template #item="{item}">
                        {{ item.text }}
                      </template>
                    </eewc-select-box>
                  </div>
                </eewc-form-field>
              </div>
              <div>
                <eewc-form-field :label="t('Floor plan name')">
                  <eewc-text-field
                    v-model="newFloor.newFloorName"
                    class="form-field-input"
                    :label="newFloor.defaultName"
                  />
                </eewc-form-field>
              </div>
              <div class="d-flex align-end">
                <div class="layout__sidebar--switch subtitle-2">
                  {{ t("Show camera names on floor plan") }}
                </div>
                <div class="d-flex">
                  <eewc-switch-button
                    v-model="showCameraNames"
                  />
                </div>
              </div>
              <div class="d-flex align-end">
                <div class="layout__sidebar--switch subtitle-2">
                  {{ t("Show camera ranges on floor plan") }}
                </div>
                <div class="d-flex">
                  <eewc-switch-button
                    v-model="showCameraFOV"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <template #buttonleft>
      <eewc-button-common
        type="outlined"
        @click="$emit('close')"
      >
        {{ t('Cancel') }}
      </eewc-button-common>
    </template>
    <template #buttonright>
      <eewc-button-common
        color="accent"
        @click="saveSettings"
      >
        {{ t('Save Changes') }}
      </eewc-button-common>
    </template>
  </eewc-sidebar>
</template>

<script setup lang="ts">
import { t } from '@eencloud/eewc-components/src/service/locale';
import { prepareAvailableFloorsLevels } from '../service/helpers';
import { useFloorsStore, useLocationsStore, useAppStore, useMessagingStore, useCamerasStore } from '@/stores';
import { computed, defineProps, defineEmits, reactive } from 'vue';
import api from '@eencloud/eewc-components/src/service/api';
import router from "@/service/router";

// stores
const floorStore = useFloorsStore();
const locationsStore = useLocationsStore();
const appStore = useAppStore();
const messagingStore = useMessagingStore();
const camerasStore = useCamerasStore();

defineProps<{
  open: boolean
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

// computed variables
const showCameraNames = computed( {
  get: () => appStore.showCameraNames,
  set: (value) => {
    appStore.$patch({ showCameraNames: value })
    localStorage.setItem('showCameraNames', String(value))
  }
});


const showCameraFOV = computed( {
  get: () => appStore.showCameraFOV,
  set: (value) => {
    appStore.$patch({ showCameraFOV: value })
    localStorage.setItem('showCameraFOV', String(value))
  }
});

const availableFloors = computed(() => {
  const usedFloors = floorStore.floors || [];
  return prepareAvailableFloorsLevels(usedFloors);
})

// Select buttons
const currentLocation = computed(() => {
  return {
    text: locationsStore?.currentLocation?.name,
    value: locationsStore?.currentLocation?.id,
    ...locationsStore?.currentLocation
  }
});

const currentFloor = computed(() => {
  return {
    text: floorStore?.currentFloor?.name,
    value: floorStore?.currentFloor?.id,
    ...floorStore?.currentFloor
  }
});

// reactive data
const newFloor = reactive({
  floorLevel: currentFloor.value.floorLevel,
  newFloorName: currentFloor.value.name,
  defaultName: currentFloor.value.name
});


// methods
const selectFloor = (floor: {text: string, value: number}) => {
  newFloor.floorLevel = floor.value;
  newFloor.newFloorName = floor.text;
  newFloor.defaultName = floor.text;
}

const savedChangesNotification = () => {
  messagingStore.addNotification(
    {
      iconColor: 'positive',
      icon: '$icon_check_zero',
      title: t('Changes saved successfully'),
      body: 'All changes you made have applied to this floor plan.',
    }
  );
  router.push({'path': `/location/${currentLocation.value.id}/floor_plan/${currentFloor.value.id}`});
  appStore.loading = false;
}

const saveSettings = async () => {
  const camerasToUpdate = floorStore.currentFloorCameras;
  appStore.loading = true;
  emit("close");
  if (!currentLocation.value.id || !currentFloor.value.id) {
    appStore.loading = false;
    return;
  }
  try {
    await api.updateFloor(currentLocation.value.id, currentFloor.value.id, {
      name: newFloor.newFloorName || newFloor.defaultName
    })
    if (currentFloor.value.floorLevel !== newFloor.floorLevel) {
      await camerasStore.performCamerasBulkUpdate(camerasToUpdate, { floor: null });
    }
    const current_floor = floorStore.getFloorById(currentFloor.value.id);
    await floorStore.getAllFloorsByLocationId();
    await floorStore.setCurrentFloor(current_floor);
    appStore.loading = false;
    savedChangesNotification();
  } catch {
    messagingStore.addNotification(
      {
        iconColor: 'negative',
        icon: '$icon_attention',
        title: t('Something went wrong'),
        body: t('Something went wrong. Please try again later'),
      }
    );
    appStore.loading = false;
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/public/main.scss";
.floorplan__sidebar-settings--container {
  overflow: hidden;
  .sidebar-settings--options-container {
    gap: 20px;
    .select-wrapper {
      max-width: 238px
    }
    .form-field-input {
      width:238px;
    }
    .layout__sidebar--switch {
      text-align: left;
      color: $primary;
      width: 332px
    }
  }
}
</style>
