<template>
  <div>
    <AppHeader />
    <div class="no-floor-plan__wrapper">
      <div>
        <eewc-loading-spinner
          v-if="floorplans.loadingFloorplans || floors.loading"
          :is-loading="floorplans.loadingFloorplans || floors.loading"
          :size="60"
          class="history-browser__player-spinner"
        />
        <eewc-empty-state
          v-else-if="locationsStore.locations.length"
          :icon-width="100"
          :icon-height="100"
          :max-width="400"
          icon="$icon_empty_floor"
          :title="t('No Floor plans')"
          :text="''"
        >
          <template #action>
            <eewc-button-common
              color="primary"
              append-icon="$icon_plus"
              @click="addFloor"
            >
              {{ t('Add floor plan') }}
            </eewc-button-common>
          </template>
        </eewc-empty-state>
        <eewc-empty-state
          v-else
          :icon-width="100"
          :icon-height="100"
          :max-width="530"
          icon="$icon_empty_locations"
          :title="t('No floor plans yet')"
          :text="t('To use floor plans, you need to have at least one site. Create a site and begin adding your floor plans.')"
        >
        </eewc-empty-state>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFloorsStore, useFloorplansStore, useLocationsStore } from '@/stores'
import { t } from '@eencloud/eewc-components/src/service/locale'
import router from "@/service/router";
import AppHeader from '@/components/AppHeader/AppHeader.vue'

const floors = useFloorsStore()
const floorplans = useFloorplansStore()
const locationsStore = useLocationsStore();

function addFloor() {
  router.push(`/new-floor-plan`);
}

</script>

<style lang="scss" scoped>
@import "../../styles/public/main.scss";

.no-floor-plan {
  &__wrapper {
    display: flex;
    overflow: hidden;
    justify-content: center;
    max-height: 100%;
    text-align: center;
  }
}

</style>
