import { defineStore } from "pinia";
import { nextTick, ref } from 'vue'
import { FloorCoords, FloorResponse, LocationGet } from '@eencloud/eewc-components/src/service/api-types'
import api from '@eencloud/eewc-components/src/service/api'
import { useAppStore } from '@/stores/app'

import SelectLocation from '@/pages/FloorPlan/NewFloorplan/steps/SelectLocation.vue'
import SelectFloor from '@/pages/FloorPlan/NewFloorplan/steps/SelectFloor.vue'
import UploadFile from '@/pages/FloorPlan/NewFloorplan/steps/UploadFile.vue'
import RenameFloor from '@/pages/FloorPlan/NewFloorplan/steps/RenameFloor.vue'
import SetLocation from '@/pages/FloorPlan/NewFloorplan/steps/SetLocation.vue'

export const useNewFloorStore = defineStore(
  'newfloor',
  function() {
    const steps = ref([
      {
        id: 'select-location',
        title: 'Select site',
        isCompleted: false,
        component: SelectLocation
      },
      {
        id: 'select-floor',
        title: 'Select floor',
        isCompleted: false,
        component: SelectFloor
      },
      {
        id: 'upload-file',
        title: 'Upload file',
        isCompleted: false,
        component: UploadFile
      },
      {
        id: 'rename-floor',
        title: 'Rename floor',
        isCompleted: false,
        component: RenameFloor
      },
      {
        id: 'set-location',
        title: 'Set site',
        isCompleted: false,
        component: SetLocation
      }
    ])

    const activeStep = ref(steps.value[0])
    const selectedLocation = ref<LocationGet>()
    const locationFloors = ref<FloorResponse[]>()
    const uploadedFileFormData = ref<FormData>()
    const uploadedFileUrl = ref<string>()
    const uploadedFile = ref<File>()
    const selectedFloor = ref<number>()
    const newFloorName = ref<string>()
    const defaultFloorName = ref<string>()
    const topLeftCoordinates = ref<FloorCoords | undefined>()
    const bottomRightCoordinates = ref<FloorCoords | undefined>()
    const appStore = useAppStore();

    async function getAllFloorsByLocationId () {
      appStore.loading = true
      locationFloors.value = undefined
      await nextTick();

      if (!selectedLocation.value?.id) {
        return
      }

      try {
        const data = await api.getFloors(selectedLocation.value.id)
        if (data) {
          const floorsResponse = data.results!
          const sortedFloors = sortByFloors(floorsResponse)

          locationFloors.value = sortedFloors
        }
      } catch (error) {
        console.error(error)
      }

      appStore.loading = false
    }

    function setIsCompletedStep(index: number, value: boolean) {
      const prevStepIndex = index - 1;
      const isFirst = index === 0;

      if (isFirst || steps.value[prevStepIndex]?.isCompleted){
        steps.value[index].isCompleted = value
      }
    }

    function setNextCompletedStep(index: number, value: boolean) {
      steps.value.map(function (step, stepIndex) {
        if (stepIndex > index) {
          setIsCompletedStep(index, value)
        }
      })
    }

    function sortByFloors(floors: FloorResponse[]) {
      return floors.sort(function (a, b) {
        return a.floorLevel - b.floorLevel;
      });
    }

    return {
      selectedLocation,
      uploadedFileFormData,
      selectedFloor,
      newFloorName,
      defaultFloorName,
      locationFloors,
      steps,
      activeStep,
      setIsCompletedStep,
      setNextCompletedStep,
      getAllFloorsByLocationId,
      uploadedFileUrl,
      uploadedFile,
      topLeftCoordinates,
      bottomRightCoordinates
    }
  }
)
